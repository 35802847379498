<template>
  <div class="pt-3">
    <v-sheet
      v-for="(order, index) in card"
      :key="index"
      height="80px"
      color="secondary"
      class="rounded-lg my-3 pb-0"
    >
      <div
        style="height: 80px"
        class="d-flex justify-space-around justify-center"
      >
        <div class=" text-center my-auto w-25">
          <div>
            <span class="font-weight-regular">{{ order.quantity }}x</span>
          </div>

          <v-btn
            width="20px"
            height="20px"
            class="rounded-circle mr-1 pa-0"
            color="accent"
            icon
            outlined
            rounded
            x-small
            @click="plus(order)"
          >
            <v-icon x-small>mdi-plus</v-icon>
          </v-btn>

          <v-btn
            class="rounded-circle pa-0 px-0"
            color="accent"
            icon
            outlined
            rounded
            x-small
            :disabled="order.quantity <= 1"
            @click="minus(order)"
          >
            <v-icon class="pa-0 px-0" x-small>mdi-minus</v-icon>
          </v-btn>
        </div>

        <div class="d-flex align-center mx-auto ">
          <div class="mx-auto text-left">
            <span class="font-weight-bold small mx-auto" style="z-index: 1;">
              {{ order.item.name }}
            </span>
          </div>

          <!--          <div>
                      <v-avatar size="60">
                        <v-img :src="order.item.image" />
                      </v-avatar>
                    </div>-->

          <div class="mx-1 tex-right">
            <strong class="accent--text small" style="z-index: 1;">
              {{ order.item.price | currency }}
            </strong>
          </div>
        </div>

        <div class="d-flex align-center mx-auto ">
          <v-btn
            class="mx-auto"
            @click="cancelItem(order.item)"
            icon
            small
            color="black"
          >
            <v-icon small>mdi-trash-can</v-icon>
          </v-btn>

          <v-btn @click="openNote(order.item)" icon small color="black">
            <v-icon small>mdi-comment</v-icon>
          </v-btn>
        </div>
      </div>
    </v-sheet>

    <!--start note dialog-->
    <item-note-dialog v-bind:dialog="note_dialog" v-model="note_dialog" />
    <!--end note dialog-->
  </div>
</template>

<script>
/*import plugins*/
import { createNamespacedHelpers } from "vuex";
import ItemNoteDialog from "@/components/localbee_table/Card/ItemNoteDialog";

export default {
  components: { ItemNoteDialog },
  data: () => ({
    note_dialog: false
  }),
  computed: {
    /*reference Order getters*/
    ...createNamespacedHelpers("Order").mapGetters(["card"]),
    orders() {
      return this.card;
    }
  },
  methods: {
    /*increment quantity*/
    plus(item) {
      this.$store.commit("Order/addToCard", item.item);
    },
    /*decrement quantity*/
    minus(item) {
      this.$store.commit("Order/changeQty", {
        item: item.item,
        quantity: parseInt(item.quantity) - 1
      });
    },
    /*remove item*/
    cancelItem(item) {
      this.$store.commit("Order/removeItem", item);
    },
    /*open note dialog*/
    openNote(item) {
      /*save current item*/
      this.$store.commit("Order/selectItem", item);

      this.note_dialog = true;
    }
  }
};
</script>
