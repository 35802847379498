var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-opacity":0.8,"transition":"","scrollable":"","max-width":"100%"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',{staticClass:"rounded-0 bg  fixed-bottom position-fixed",attrs:{"elevation":"10","tile":"","min-height":"100%","max-height":"90%"}},[_c('div',{style:({
        backgroundImage: ("linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(0, 0, 0, 0.73), rgba(0, 0, 0, 0.73)), url('" + (_vm.item.image) + "')")
      }),attrs:{"id":"header_image"}},[_c('v-btn',{staticClass:"pa-0 px-0 mt-3 ml-3",attrs:{"min-width":"40","max-width":"40","min-height":"40","max-height":"40","id":"back_btn","color":"#ffffffab","small":"","elevation":"0"},on:{"click":function($event){_vm.visible = false}}},[_c('v-icon',{staticClass:"pa-0",attrs:{"large":"","color":"primary"}},[_vm._v(" mdi-chevron-left ")])],1)],1),_c('v-card-title',{directives:[{name:"touch",rawName:"v-touch",value:({
        down: function () { return _vm.swipeDown(); }
      }),expression:"{\n        down: () => swipeDown()\n      }"}],staticClass:"text-left bg top-rounded mb-0 white",staticStyle:{"margin-top":"-50px"}},[_c('i',{staticClass:"mx-auto mt-2",attrs:{"id":"top-divider"}}),_c('v-list-item',{staticClass:"mb-0 pb-0"},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.item.name)+" ")])],1)],1)],1),_c('v-card-text',{attrs:{"min-height":"100%"}},[_c('p',{staticClass:"pt-3"},[_vm._v(" "+_vm._s(this.item.description)+" ")]),_c('v-expansion-panels',{staticClass:"elevation-0 bg pa-0 ma-0 pt-2"},[_c('v-expansion-panel',{staticClass:"elevation-0 bg pa-0 ma-0"},[_c('v-expansion-panel-header',{staticClass:"elevation-0 pa-0 ma-0"},[_c('h5',{staticClass:"text-muted"},[_vm._v("Allergens")])]),_c('v-expansion-panel-content',{staticClass:"bg"},[(
                Array.isArray(this.item.allergens) &&
                  this.item.allergens.length > 0
              )?_c('v-list',{staticClass:"bg"},_vm._l((this.item.allergens),function(allergen){return _c('v-list-item',{key:allergen.id,staticClass:"pt-0 pb-0"},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"accent","small":""}},[_vm._v("mdi-square")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(allergen.name)+" ")])],1)],1)}),1):_c('p',{staticClass:"success--text"},[_vm._v(" this item have no allergens ")])],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"mx-auto shadow-lg",attrs:{"elevation":"0","large":"","disabled":_vm.checkItem(_vm.item.id),"color":"primary","block":""}},[(_vm.checkItem(_vm.item.id))?_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-check ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(this.item.price))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }