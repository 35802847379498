<template>
  <!--***-->
  <div class="rounded-lg mx-4 mt-2">
    <v-card
      id="itemCard"
      outlined
      class="align-center text-start rounded-lg px-0"
      elevation="0"
    >
      <v-card-text class="pa-0 px-0 ma-0 mx-0">
        <!--***-->
        <v-list-item three-line class="mx-0">
          <v-list-item-content>
            <v-list-item-title
              @click="setCurrent"
              class="font-weight-bold mb-0 text-lg-h6 black--text "
            >
              {{ item.name }}
            </v-list-item-title>

            <v-list-item-subtitle @click="setCurrent" class="grey--text">
              {{ item.description && item.description.substring(0, 30) }}
            </v-list-item-subtitle>

            <h6 class="py-2 primary--text">
              {{ item.price | currency }}
            </h6>

            <p @click="details" class="accent--text small">
              Produktinfo
            </p>
          </v-list-item-content>

          <v-list-item-avatar @click="setCurrent" tile size="80">
            <img height="120" :src="item.image" alt="Image" />
          </v-list-item-avatar>

          <v-list-item-action @click="setCurrent" class="">
            <v-btn
              v-if="!isCurrent(item.id)"
              min-width="30"
              min-height="30"
              class="rounded-md position-absolute item_btn"
              color="primary"
              dark
              elevation="0"
              absolute
              @click="open"
            >
              <v-icon small>mdi-plus</v-icon>
            </v-btn>

            <v-btn
              v-else
              min-width="30"
              min-height="30"
              class="rounded-md position-absolute  item_btn"
              color="error"
              dark
              elevation="0"
              absolute
              @click="close"
            >
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <!--***-->
      </v-card-text>
      <v-card-actions
        class="border-top pb-0 pt-6 tertiary lighten-3"
        v-if="this.isCurrent(this.item.id)"
      >
        <v-row no-gutters class="pb-0 pr-3 pl-3">
          <v-col cols="12" class="pt-3 pb-3">
            <strong>
              Extras
            </strong>

            <!-- start extras -->
            <v-expansion-panels
              accordion
              style="z-index: 1 !important;"
              class="elevation-0 pa-0 ma-0 pt-2"
            >
              <v-expansion-panel
                v-for="tab in item.extras"
                class="elevation-0 pa-0 ma-0"
                :key="tab.id"
                style="z-index: 1 !important;"
              >
                <v-expansion-panel-header class="elevation-0 pa-0 pl-2 ma-0">
                  <h5 class="text-muted">{{ tab.tabName }}</h5>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-checkbox
                    :input-value="isExtrasSelected(ext.id)"
                    @change="pushExtras($event, ext)"
                    class="pt-0 pb-0"
                    hide-details
                    v-for="ext in tab.garnish"
                    :value="true"
                    :label="ext.name"
                    :key="ext.id"
                    v-show="ext.active"
                  >
                    <template v-slot:label>
                      {{ ext.name }}
                      <strong class="primary--text ml-1">{{
                        ext.sellingPrice | currency
                      }}</strong>
                    </template>
                  </v-checkbox>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- end extras -->
          </v-col>
          <v-col cols="6" class="pb-0 mx-auto text-left">
            <v-text-field
              height="40px"
              v-model="quantity"
              readonly
              dense
              class="text-center centered-input"
              outlined
            >
              <template v-slot:prepend-inner>
                <v-icon @click="minus">mdi-minus</v-icon>
              </template>

              <template v-slot:append>
                <v-icon @click="plus">mdi-plus</v-icon>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="6" class="pb-0 text-left">
            <v-btn
              @click="addToCard(item)"
              height="40px"
              class="mx-auto"
              block
              elevation="0"
              color="primary"
            >
              {{
                (this.item.price * this.quantity + this.currentExtrasTotal)
                  | currency
              }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <product-info-dialog
      v-bind:item="this.item"
      v-bind:dialog="this.details_dialog"
      v-model="details_dialog"
    />
  </div>
  <!--***-->
</template>

<script>
/**
 * import plugins
 */
import { createNamespacedHelpers } from "vuex";
import ProductInfoDialog from "@/components/localbee_table/Menu/ProductInfoDialog";

export default {
  components: { ProductInfoDialog },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      state: null, // to control details,
      details_dialog: false
    };
  },
  computed: {
    ...createNamespacedHelpers("Order").mapGetters([
      "isCurrent",
      "currentQuantity",
      "currentExtrasTotal",
      "isExtrasSelected"
    ]),
    /**
     * item quantity accessors
     * */
    quantity: {
      get() {
        return this.currentQuantity;
      },
      set(val) {
        this.setCurrentQuantity(val);
      }
    },
    extras_model: {
      get() {
        const self = this;
        return id => self.isExtrasSelected(id);
      }
    }
  },
  methods: {
    ...createNamespacedHelpers("Order").mapMutations([
      "setCurrentQuantity",
      "pushExtrasToCurrent",
      "removeExtraFromCurrent"
    ]),
    /*add item to card in Order state*/
    addToCard(item) {
      this.$store.commit("Order/addToCard", item);

      //change quantity to the current one
      this.$store.commit("Order/changeQty", {
        item: item,
        quantity: this.quantity
      });

      //clear current item
      this.$store.commit("Order/selectCurrent", {});
    },
    /**
     * open details according
     */
    open() {
      this.state = true;
    },

    /**
     * close details according
     */
    close() {
      this.state = true;
    },
    pushExtras(value, ext) {
      if (value) {
        this.pushExtrasToCurrent(ext);
      } else {
        this.removeExtraFromCurrent(ext);
      }
    },
    setCurrent() {
      //let's check if this item don't have any extras
      if (
        !Array.isArray(this.item.extras) ||
        (Array.isArray(this.item.extras) && this.item.extras.length === 0)
      ) {
        //then we select the item directly
        this.$store.commit("Order/addToCard", this.item);
        //clear current item
        this.$store.commit("Order/selectCurrent", {});
      }
      //we show product details (extras ...)
      else this.$store.commit("Order/selectCurrent", this.item);
    },
    minus() {
      if (this.quantity > 1) this.quantity = this.quantity - 1;
    },
    plus() {
      this.quantity = this.quantity + 1;
    },
    /**
     * show product details dialog
     */
    details() {
      this.details_dialog = true;
    }
  }
};
</script>

<style scoped>
.item_btn {
  position: absolute !important;
  top: 0px !important;
  right: -1px;
  max-width: 20px !important;
  max-height: 20px !important;
}

.centered-input {
  width: 100px !important;
}

.centered-input >>> input {
  text-align: center;
}
</style>
