<template>
  <v-dialog
    :overlay-opacity="0.8"
    transition
    scrollable
    max-width="100%"
    v-model="visible"
  >
    <v-card
      elevation="10"
      tile
      class="rounded-0 bg  fixed-bottom position-fixed"
      min-height="100%"
      max-height="90%"
    >
      <!-- header background -->
      <div
        id="header_image"
        v-bind:style="{
          backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(0, 0, 0, 0.73), rgba(0, 0, 0, 0.73)), url('${item.image}')`
        }"
      >
        <!-- back button -->
        <v-btn
          @click="visible = false"
          min-width="40"
          max-width="40"
          min-height="40"
          max-height="40"
          id="back_btn"
          class="pa-0 px-0 mt-3 ml-3"
          color="#ffffffab"
          small
          elevation="0"
        >
          <v-icon large color="primary" class="pa-0">
            mdi-chevron-left
          </v-icon>
        </v-btn>
      </div>
      <!--start title icon-->
      <v-card-title
        v-touch="{
          down: () => swipeDown()
        }"
        style="margin-top: -50px"
        class="text-left bg top-rounded mb-0 white"
      >
        <i id="top-divider" class="mx-auto mt-2"></i>
        <v-list-item class="mb-0 pb-0">
          <v-list-item-content>
            <v-list-item-title class="text-h5">
              {{ item.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card-title>
      <!--end title icon-->
      <v-card-text min-height="100%" class="">
        <!--description-->
        <p class="pt-3">
          {{ this.item.description }}
        </p>

        <!-- start allergens -->
        <v-expansion-panels class="elevation-0 bg pa-0 ma-0 pt-2">
          <v-expansion-panel class="elevation-0 bg pa-0 ma-0">
            <v-expansion-panel-header class="elevation-0 pa-0 ma-0">
              <h5 class="text-muted">Allergens</h5>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="bg">
              <v-list
                v-if="
                  Array.isArray(this.item.allergens) &&
                    this.item.allergens.length > 0
                "
                class="bg"
              >
                <v-list-item
                  class="pt-0 pb-0"
                  :key="allergen.id"
                  v-for="allergen in this.item.allergens"
                >
                  <v-list-item-icon>
                    <v-icon color="accent" small>mdi-square</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ allergen.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <p class="success--text" v-else>
                this item have no allergens
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>

      <v-card-actions>
        <v-btn
          elevation="0"
          large
          :disabled="checkItem(item.id)"
          color="primary"
          block
          class="mx-auto shadow-lg"
        >
          <v-icon large v-if="checkItem(item.id)">
            mdi-check
          </v-icon>
          <span v-else>
            {{ this.item.price | currency }}
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * i;port plugins
 */
import { createNamespacedHelpers } from "vuex";

export default {
  name: "ProductInfoDialog",
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...createNamespacedHelpers("Order").mapGetters(["checkItem"]),
    visible: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    /*close dialog on swipe down*/
    swipeDown() {
      this.visible = false;
    }
  }
};
</script>

<style scoped>
.top-rounded {
  border-top-left-radius: 50px !important;
  border-top-right-radius: 50px !important;
}

#top-divider {
  width: 50px;
  height: 4px;
  background-color: lightgrey;
  border-radius: 30px;
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  top: 5px !important;
}

#header_image {
  min-height: 200px !important;
  background-position: center;
  background-size: cover;
}

#back_btn {
  width: 30px !important;
  height: 30px !important;
  padding: 0 !important;
  border-radius: 13px;
}
</style>
