<template>
  <div>
    <!--Start NavBar-->
    <v-toolbar color="primary" height="50px" flat>
      <div>
        <v-img src="@/assets/LocalBee white.svg" alt="Logo" width="40px" />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        fab
        outlined
        small
        text
        @click.stop="drawer = !drawer"
        :color="menuIconColor"
        v-if="this.order && this.order.id > 0"
      >
        <v-icon size="30px">mdi-cart-check</v-icon>
      </v-btn>
    </v-toolbar>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      color="white"
      style="top: 0%; bottom: 0%;min-width: 100%"
    >
      <v-sheet id="qr-sheet" color="align-center transparent   rounded-b-lg">
        <v-btn @click="drawer = false" text class="pl-0 mt-2 ml-1">
          <v-icon class="pl-0 ml-0 ">mdi-chevron-left</v-icon>
          <span class="text-muted">Menu</span>
        </v-btn>
      </v-sheet>
      <v-card class="" elevation="0">
        <v-card-title>
          <div
            id="qr-container"
            class="pa-5 mx-auto mt-0  rounded-circle primary gradient"
          >
            <qr-code
              :color="this.$vuetify.theme.themes.light.primary.base"
              class="mx-auto ma-0"
              :text="178282 + ''"
              :size="81"
              level="H"
            />
          </div>
        </v-card-title>
        <v-card-text class="pa-0 fill-height">
          <div class="text-center primary--text">
            <h4>
              {{ this.party }}
            </h4>
          </div>
          <!-- list items -->
          <v-container fluid class="pa-0 rounded-lg mt-5 fill-height">
            <v-list class="mx-4 w-100">
              <v-list-item
                class="pr-0 mr-0 mt-1 bg rounded-lg"
                v-for="item in ordered"
                :key="item.id"
              >
                <v-list-item-avatar>
                  <v-img :src="item.item.image" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.item.sellingPrice | currency }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text class="pr-3">
                    x <strong class="primary--text">{{ item.quantity }}</strong>
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-container>
          <!-- end list items -->
        </v-card-text>

        <!--        <v-card-actions class="">
                  <v-btn block depressed color="primary">
                    new order
                  </v-btn>
                </v-card-actions>-->
      </v-card>
    </v-navigation-drawer>
    <!--End NavBar-->
  </div>
</template>

<script>
/**
 * import plugins
 */
import { createNamespacedHelpers } from "vuex";

/**
 * import components
 */
import VueQRCodeComponent from "vue-qrcode-component";

export default {
  name: "Header",
  components: {
    "qr-code": VueQRCodeComponent
  },
  data() {
    return {
      menuIconColor: "white",
      drawer: null,
      navItems: [
        { title: "Home", icon: "mdi-view-dashboard", link: "#" },
        { title: "About", icon: "mdi-forum", link: "#" }
      ]
    };
  },
  computed: {
    ...createNamespacedHelpers("Table").mapGetters(["party"]),
    ...createNamespacedHelpers("Order").mapGetters([
      "total",
      "order",
      "ordered"
    ])
  }
};
</script>

<style scoped>
#qr-container {
  width: 122px;
  height: 122px;
}

#qr-sheet {
  position: relative;
}
</style>
