<template>
  <div>
    <!--Start shopping cart-->
    <div class="text-center">
      <v-dialog
        v-model="visible"
        width="100%"
        fullscreen
        transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <!--Start Menu Page Basket button-->
          <v-btn
            width="100%"
            height="65px"
            :color="can_change_color ? 'primary' : 'accent'"
            class="text-capitalize"
            dark
            v-bind="attrs"
            v-on="on"
            style="border-top-right-radius: 60px; border-top-left-radius: 60px; position: fixed; bottom: 0%; right: 0%; left: 0%; z-index: 4"
          >
            <!--todo:HI please remove style attr and use class or id to apply this style -->
            <div class="d-flex justify-center">
              <div>
                <v-badge
                  :content="NProductSelect"
                  :value="NProductSelect"
                  color="primary"
                  overlap
                  bottom
                >
                  <v-icon class="mt-0" size="32px" color="white" dark>
                    mdi-purse
                  </v-icon>
                </v-badge>
              </div>

              <div>
                <h5
                  class="font-weight-regular font-size-sm white--text pl-4 pt-2"
                >
                  Warenkorb ({{ total | currency }})
                </h5>
              </div>
            </div>
          </v-btn>
          <!--End Menu Page Basket button-->
        </template>

        <!--Start Shopping Cart Content-->
        <v-card color="bg">
          <v-toolbar elevation="0" class="py-8 px-2" color="#F8F4F1">
            <v-toolbar-items class="d-flex">
              <div>
                <v-toolbar-title
                  class="font-weight-medium font-size-sm pr-2 primary--text text-h6"
                >
                  Warenkorb
                </v-toolbar-title>
              </div>

              <div>
                <v-badge
                  :content="NProductSelect"
                  :value="NProductSelect"
                  color="accent"
                  overlap
                  bottom
                  bordered
                >
                  <v-icon color="primary" size="27px">
                    mdi-purse-outline
                  </v-icon>
                </v-badge>
              </div>
            </v-toolbar-items>

            <v-spacer></v-spacer>

            <v-btn
              class="pb-6"
              x-large
              color="primary"
              icon
              dark
              @click="visible = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <div class="mx-5">
            <v-divider></v-divider>
          </div>

          <div class="mx-6">
            <h6 class="mt-8 font-weight-bolder">Deine Auswahl</h6>
          </div>

          <!--Start Shopping Cart Orders-->
          <div class="mx-6">
            <shopping-cart-orders-card />
          </div>
          <!--End Shopping Cart Orders-->

          <!--start order note-->
          <div class="mx-6">
            <v-textarea
              v-model="order_note"
              height="80"
              dense
              outlined
              prepend-inner-icon="mdi-comment"
              label="Type your note"
            />
          </div>
          <!--end order note-->

          <div class="px-6 mt-3 mx-auto text-center">
            <div class="d-flex">
              <span class="font-weight-bold primary--text">Gesamt</span>
              <v-spacer></v-spacer>
              <span class="font-weight-bold primary--text">
                {{ this.total | currency }}
              </span>
            </div>

            <p class="mt-4 small grey--text">
              Du mochtest deine Bestellungen mitnehmen? Wahle "Take Away" aus,
              nachdem du auf Bestellen druckst.
            </p>
          </div>

          <v-sheet height="200px" color="transparent" />

          <!--Start dialog Basket button-->
          <v-card-actions>
            <v-btn
              @click="redirectToCheckout()"
              width="100%"
              height="65px"
              color="accent"
              dark
              class="text-capitalize"
              style="border-top-right-radius: 60px; border-top-left-radius: 60px; position: fixed; bottom: 0%; right: 0%; left: 0%;"
            >
              <div class="d-flex justify-center">
                <h4 class="font-weight-regular white--text pt-1 pr-2">
                  Bestellen
                </h4>

                <v-icon size="26px" color="white" dark>
                  mdi-arrow-right
                </v-icon>
              </div>
            </v-btn>
          </v-card-actions>
          <!--End Dialog Basket button-->
        </v-card>
        <!--End Shopping Cart Content-->
      </v-dialog>
    </div>
    <!--End shopping cart-->
  </div>
</template>

<script>
/*import components*/
import shoppingCartOrdersCard from "@/components/localbee_table/Card/shoppingCartOrdersCard";

/*import plugins*/
import { createNamespacedHelpers } from "vuex";

export default {
  components: { shoppingCartOrdersCard },

  methods: {
    //Redirect To Checkout Page
    redirectToCheckout() {
      this.$router.push({ name: "Checkout" });
    }
  },

  props: {
    dialog: Boolean,
    orders: []
  },
  watch: {
    total(val) {
      if (val) {
        this.can_change_color = true;
        const self = this;
        let timer = setTimeout(() => {
          self.can_change_color = false;
          clearTimeout(timer);
        }, 250);
      }
    }
  },
  computed: {
    ...createNamespacedHelpers("Order").mapGetters([
      "isCardEmpty",
      "card",
      "total",
      "note"
    ]),
    /*get number of selected items*/
    NProductSelect() {
      return this.card.length;
    },
    /*for:hossaine, we're using computed attr to oid mutating a 'dialog' prop directly*/
    visible: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    /*order note accessors*/
    order_note: {
      get() {
        return this.note;
      },
      set(val) {
        this.$store.commit("Order/setOrderNote", val);
      }
    }
  },
  data() {
    return {
      //to change color when we add  a new item
      can_change_color: false
    };
  }
};
</script>
