<template>
  <v-container
    fluid
    id="container"
    class="mx-auto overflow-hidden pa-0 white"
    color="white"
    tile
    elevation="0"
  >
    <!--Start NavBar-->
    <Header />
    <!--End NavBar-->

    <div class="text-end d-flex">
      <v-spacer></v-spacer>
      <div>
        <h6
          class="py-2 px-5 my-4 font-weight-bold tertiary lighten-3"
          style="border-top-left-radius: 50px; border-bottom-left-radius: 50px;"
        >
          Tisch Nr. {{ this.table }}
        </h6>
      </div>
    </div>

    <v-row>
      <v-col cols="12">
        <v-row no-gutters align="center" justify="center">
          <v-col cols="9" xs="9" sm="9" class="pl-8">
            <h5 class="font-weight-bold text-h5">Hey, {{ this.party }}!</h5>
            <h6 class="grey--text">wahle dein Essen aus</h6>
          </v-col>

          <!--Start Notification Badge-->
          <v-col cols="3" xs="3" sm="3" class="pl-9">
            <v-badge
              :content="notification"
              :value="notification"
              color="accent"
              overlap
              class="mx-auto"
            >
              <v-icon size="30">
                mdi-bell-outline
              </v-icon>
            </v-badge>
          </v-col>
          <!--End Notification Badge-->
        </v-row>
      </v-col>

      <!--Start Search input-->
      <v-col cols="12" class="pl-7 pr-7">
        <v-text-field
          placeholder="Gerichte suchen"
          dense
          outlined
          v-model="search"
          autocomlete="false"
          hide-details
        >
          <!--solo-inverted-->
          <template v-slot:append>
            <v-btn
              fab
              dark
              elevation="0"
              x-small
              color="transparent"
              @click="isShowingFilter = true"
              id="filter_icon"
            >
              <v-icon dark color="primary" size="24px">
                mdi-tune
              </v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
      <!--End Search input-->

      <!--Start Filter info-->
      <!--      <v-col cols="3" xs="3" sm="3" class="pr-6 text-right">
              <v-btn
                  fab
                  dark
                  elevation="0"
                  x-small
                  color="tertiary lighten-3 pa-5 mb-1"
                  @click="isShowingFilter = true"
              >
                <v-icon dark color="primary" size="24px">
                  mdi-tune
                </v-icon>
              </v-btn>
            </v-col>-->

      <!--  Start filter component  -->
      <filter-component
        v-model="isShowingFilter"
        v-bind:dialog="isShowingFilter"
      />
      <!--  End filter component  -->
      <!--End Filter info-->
    </v-row>

    <!--Start Products Menu-->
    <menu-items v-bind:search="this.search" />
    <!--End Products Menu-->
  </v-container>
</template>

<script>
/*import: components*/
import Header from "@/components/localbee_table/Widget/Header.vue";
import menuItems from "@/components/localbee_table/Menu/menuItems.vue";

/*import: plugins*/
import { createNamespacedHelpers } from "vuex";
import FilterComponent from "@/components/localbee_table/Menu/FilterComponent";

export default {
  components: { FilterComponent, Header, menuItems },
  computed: {
    ...createNamespacedHelpers("Table").mapGetters(["table", "party"]),
    /*sync search input with vuex*/
    search: {
      get() {
        return this.$store.getters["Filter/search"];
      },
      set(val) {
        this.$store.commit("Filter/setSearch", val);
      }
    }
  },
  data() {
    return {
      notification: 0,
      isShowingFilter: false,

      tab: null
    };
  }
};
</script>

<style scoped>
#container {
  height: 100% !important;
}

#filter_icon {
  position: absolute;
  right: 4px;
  top: 4px;
}
</style>
